module.exports = {
    henghuat:[
        require('../assets/images/henghuat/heng_huat_1.jpeg'),
        require('../assets/images/henghuat/heng_huat_2.jpeg'),
        require('../assets/images/henghuat/heng_huat_3.jpeg'),
        require('../assets/images/henghuat/heng_huat_4.jpeg'),
        require('../assets/images/henghuat/heng_huat_5.jpeg'),
        require('../assets/images/henghuat/heng_huat_6.jpeg'),
        require('../assets/images/henghuat/heng_huat_7.jpeg'),
        require('../assets/images/henghuat/heng_huat_8.jpeg'),
        require('../assets/images/henghuat/heng_huat_9.jpeg')
    ],
    dragonGlobal:[
        require('../assets/images/dragonGlobal/dragon_global_1.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_2.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_3.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_4.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_5.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_6.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_7.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_8.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_10.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_11.jpeg'),
        require('../assets/images/dragonGlobal/dragon_global_12.jpeg')
    ],
    elColdChain:[
        require('../assets/images/elColdChain/el_cold_1.jpg'),
        require('../assets/images/elColdChain/el_cold_2.jpg'),
        require('../assets/images/elColdChain/el_cold_3.jpg'),
        require('../assets/images/elColdChain/el_cold_4.jpg'),
        require('../assets/images/elColdChain/el_cold_5.jpeg'),
    ],
    hpo:[
        require('../assets/images/hpo/hpo_1.jpeg'),
        require('../assets/images/hpo/hpo_2.jpeg'),
        require('../assets/images/hpo/hpo_3.jpeg'),
        require('../assets/images/hpo/hpo_4.jpeg'),
        require('../assets/images/hpo/hpo_5.jpeg'),
        require('../assets/images/hpo/hpo_6.jpeg'),
        require('../assets/images/hpo/hpo_7.jpeg'),
    ],
}