<template>
  <div>
    <b-carousel
      id="carousel"
      v-model="slide"
      :interval="3500"
      controls
      indicators
      img-width="1024"
      img-height="100%"
      class="courasel-container"
    >
      <b-carousel-slide 
        v-for="(image,index) in sliderImages"
        :key="index"
        :img-src="image" class="swiper-image"></b-carousel-slide>
    </b-carousel>

  </div>
</template>

<script>
  export default {
    name:"Swiper",
    props:{
        sliderImages:{
            type:Object,
            default:()=> {}
        },

    },
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>


</style>