<template>
  <div class="d-flex" :style="`width:${iconContainerSize}`">
      <div :style="`width:${iconSize}`" v-if="hasFacebook">
        <div class="icon-container">
            <a :href="facebookLink" target="_blank">
                <img :src="facebookSvg" />
            </a>
        </div>
      </div>
      <div :style="`width:${iconSize}`" v-if="hasWhatsapp">
        <div class="icon-container">
            <a :href="whatsappLink" target="_blank">
                <img :src="whatsappSvg" />
            </a>
        </div>
      </div>
      <div :style="`width:${iconSize}`" v-if="hasInstagram">
        <div class="icon-container">
            <a :href="instagramLink" target="_blank">
                <img :src="instagramSvg" />
            </a>
        </div>
      </div>
      <div :style="`width:${iconSize}`" v-if="hasYoutube">
        <div class="icon-container">
            <a :href="youtubeLink" target="_blank">
                <img :src="youtubeSvg" />
            </a>
        </div>
      </div>
  </div>
</template>

<script>
import facebookSvg from '@/assets/icons/facebook.svg'
import instagramSvg from '@/assets/icons/instagram.svg'
import whatsappSvg from '@/assets/icons/whatsapp.svg'
import youtubeSvg from '@/assets/icons/youtube.svg'
export default {
    name:"Icons",
    props:{
        iconContainerSize:{
            type:String,
            default:"100%"
        },
        iconSize:{
            type:String,
            default:"25%"
        },
        hasFacebook:{
            type:Boolean,
            default:false
        },
        hasInstagram:{
            type:Boolean,
            default:false
        },
        hasWhatsapp:{
            type:Boolean,
            default:false
        },
        hasYoutube:{
            type:Boolean,
            default:false
        },
        facebookLink:{
            type:String,
            default:""
        },
        instagramLink:{
            type:String,
            default:""
        },
        whatsappLink:{
            type:String,
            default:""
        },
        youtubeLink:{
            type:String,
            default:""
        },
    },
    data(){
        return{
            facebookSvg:facebookSvg,
            whatsappSvg:whatsappSvg,
            instagramSvg:instagramSvg,
            youtubeSvg:youtubeSvg
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-container{
    max-width: 50px;
    cursor: pointer;

    img{
        width:100%;
        height:100%;
    }
}
</style>