module.exports = {
    elColdChain:{
        services:[
            {
                title:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.TITLE_1",
                description:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_1"
            },
            {
                title:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.TITLE_2",
                description:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_2"
            },
            {
                title:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.TITLE_3",
                description:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_3"
            },
            {
                title:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.TITLE_4",
                description:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_4"
            },
            {
                title:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.TITLE_5",
                description:"COMPANY.SERVICES.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_5"
            },
        ],
        purpose:[
            {
                icon: require('../assets/icons/pallet.png'),
                title:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.TITLE_1",
                description:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_1"
            },
            {
                icon: require('../assets/icons/lifespan.png'),
                title:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.TITLE_2",
                description:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_2"
            },
            {
                icon: require('../assets/icons/certificate.png'),
                title:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.TITLE_3",
                description:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_3"
            },
            {
                icon: require('../assets/icons/cloud.png'),
                title:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.TITLE_4",
                description:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_4"
            },
            {
                icon: require('../assets/icons/cctv.png'),
                title:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.TITLE_5",
                description:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_5"
            },
            {
                icon: require('../assets/icons/temperature.png'),
                title:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.TITLE_6",
                description:"COMPANY.PURPOSE.EL_COLD_CHAIN_LOGISTIC.DESCRIPTION_6"
            },
        ]
    },
    hengHuat:{
        history:[
            {description:"COMPANY.HISTORY.HENG_HUAT.H_1"},
            {description:"COMPANY.HISTORY.HENG_HUAT.H_2"},
            {description:"COMPANY.HISTORY.HENG_HUAT.H_3"},
            {
                description:"COMPANY.HISTORY.HENG_HUAT.H_4"
            },
            {description:"COMPANY.HISTORY.HENG_HUAT.H_5"},
            {description:"COMPANY.HISTORY.HENG_HUAT.H_6"},
        ]

        
    }
}